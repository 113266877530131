.board {
  background-color: #eee4da;
  border-radius: 5px;

  border: 1px solid #999;
  width: 80%;
  margin:auto;
}
.board-row {
  display: flex;
}

  

