/* style for tiles in 2048*/
$sizeColors :(
  2: #eee4da,
  4: #ede0c8,
  8: #f2b179,
  16: #f59563,
  32: #f67c5f,
  64: #f65e3b,
  128: #edcf72,
  256: #edcc61,
  512: #edc850,
  1024: #edc53f,
  2048: #edc22e
);
.tile {
  width: 25%;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 55px;
  text-align: center;
  color: #776e65;
  border: 1px solid #999;
}

@each $size, $color in $sizeColors {
  .tile-#{$size} {
    background-color: $color;
    @if $size > 4 {
      color: #f9f6f2;
    }
    @if $size > 64 {
      font-size: 25px;
      line-height: 60px;
    }
    @if $size > 512 {
      font-size: 20px;
    }
  }
}

.bigger {
  animation: bigger 0.5s ease forwards;

}
@keyframes bigger {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.moving {
  animation: move 0.20s ease forwards;
}
@keyframes move {
  from {
    z-index: 100;
  }
  to {
    transform: translate(var(--toX), var(--toY));
    z-index: 1;
  }
}

